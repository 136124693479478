import { UserProfile } from './user.model';

export enum ReactionType {
  COMMENT = 'comment',
  ARTICLE = 'article',
}

interface ReactionBase {
  emoji: string;
  count: number;
}

export interface Reaction extends ReactionBase {
  currentUserReacted: boolean;
  userReactionId: number;
}

export interface ReactionDialog extends ReactionBase {
  users: UserProfile[];
}
