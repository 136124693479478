import { Entity } from '@pinup-teams/common';
import { UserProfile } from '@pt/models';

export enum GroupTypeEnum {
  Dynamic = 'dynamic',
  AdvancedDynamic = 'advanced_dynamic',
  Individual = 'individual',
  FromTable = 'from_table',
}

export enum GroupByGroupFieldNameEnum {
  Location = 'location',
  Team = 'team',
  Position = 'position',
  Segment = 'segment',
  Status = 'status',
  Function = 'function',
  users = 'users',
  Id = 'id',
  IsDirectManager = 'direct_reports',
  IsFunctionalManager = 'functional_reports',
  IsPeoplePartner = 'partner_reports',
  YearsInCompany = 'years_in_company',
  DaysSinceStart = 'days_since_start',
  UserCourseParticipationCourseId = 'usercourseparticipation__course_id',
  UserCourseParticipationStatus = 'usercourseparticipation__status',
  PeoplePartners = 'people_partner_id',
}

export enum GroupTagEnum {
  Courses = 'courses',
  FAQ = 'faq',
  Messages = 'messages',
  News = 'news',
  Coins = 'coins',
  Survey = 'survey',
  CoursesNotification = 'courses_notification',
  PrivateEvents = 'private_events',
}

export type GroupAdminType = `${GroupTypeEnum}`;

export interface GroupQuery {
  field: string;
  value: string;
}

export enum Condition {
  And = 'and',
  Or = 'or',
}

export interface Field {
  field: string;
  operation: string;
  value: string[] | UserProfile[] | string;
}

export interface Rule {
  condition: Condition;
  rules: Array<Rule | Field>;
}

export interface Group extends Entity {
  name: string;
}

export interface GroupAdmin extends Group {
  visible: boolean;
  usersCount: number;
  tagTypes: GroupTagEnum[];
}

export interface GoogleTable {
  id: string;
  name: string;
}

export interface GoogleSheet {
  id: number;
  sheetTitle: string;
}

export interface GroupGoogleTable {
  tableId: string;
  sheetId: number;
  column: string;
  row: number;
}

export interface GroupDetailsAdmin extends GroupAdmin {
  groupType: GroupAdminType;
  query?: GroupQuery[];
  users?: UserProfile[];
  ruleConfig?: Rule;
  googleTable?: GroupGoogleTable;
}

export interface GroupByGroup {
  fieldName: GroupByGroupFieldNameEnum;
  displayName: string;
  possibleValues: (string | [string, string])[];
  operations: string[];
}
