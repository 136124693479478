export enum EnvName {
  DEV = 1,
  STAGE,
  PROD,
}

/**
 * Environment configuration model
 */
export interface EnvironmentModel {
  /**
   * Application short name
   */
  shortName: string;

  /**
   * Application name
   */
  appName: string;

  /**
   * Transaction name
   */
  appNameForTransaction: string;

  /**
   * If this app runs in production mode
   */
  production: boolean;

  /**
   * If you set this option, api methods will return mock data without requests on api server
   */
  useMocks: boolean;

  /**
   * Display name of the environment. Useful for debugging.
   */
  envName: EnvName;

  /**
   * Hostname of the backend server
   */
  apiHost: string;

  /**
   * Hostname of websocket server
   */
  wssHost: string;

  /**
   * Hostname of LMS server
   */
  lmsHost: string;

  /**
   * Hostname of assets
   */
  assetsHost: string;

  /**
   * The main domain which keeps up cookie for all subdomains
   */
  cookieDomain: string;

  /**
   * Postfix for auth token names
   */
  tokenPostfix: string;

  /**
   * Default lang
   */
  defaultLang: string;

  /**
   * Supported ui languages
   */
  supportedUiLanguages: string[];

  /**
   * UI theme
   */
  theme: 'dark' | 'light';

  /**
   * Google client id for auth
   */
  googleClientId: string;

  /**
   * Slack team id
   */
  slackTeamId: string;

  /**
   * Slack workspace
   */
  slackWorkspace: string;

  /**
   * Flag for image optimization
   */
  imagesOptimizationEnabled: boolean;

  /**
   * Admin slug
   */
  adminSlug?: string;

  /**
   * Url of Floating Button WebComponent
   */
  floatingButtonUrl?: string;

  /**
   * Hostname of trainee server
   */
  traineeHost?: string;

  /**
   * Google Analytics Manager id *ONLY FOR PROD*
   */
  googleAnalyticsId?: string;

  /**
   * Google Analytics affiliation to ENV
   */
  affiliation?: string;
}
