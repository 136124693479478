import { Entity, Nullable } from '@pinup-teams/common';
import { Achievement, UserQuestionnaireStatus, UserStagesProgress } from '@pt/models';

export interface UserGroup extends Entity {
  name: string;
}

export interface User extends Entity {
  fullName: string;
  login?: string;
}

export interface UserProfile extends User {
  imageUrl: string;
  position: string;
  email: string;
}

export interface Author extends UserProfile {
  subscribesCount: number;
  isSubscribed: boolean;
}

export interface Newcomer extends UserProfile {
  questionnaireImageUrl: Nullable<string>;
}

export interface Manager extends User {
  picture: string;
  position: string;
  email: string;
}

export interface Managers {
  directManager: Manager | null;
  functionalManager: Manager | null;
  peoplePartner: Manager | null;
}

export interface MainUserInfo {
  email: string;
  location: string;
  locationId: number;
  birthday: string;
  team: string;
  segment: string;
  personalYearStart: string;
  personalYearEnd: string;
}

export interface Colleague
  extends User,
  Managers,
  UserSlackId,
  UserVacationInfo,
  NotifyOnBday,
  Omit<MainUserInfo, 'personalYearStart' | 'personalYearEnd'> {
  achievements: Achievement[];
  hobby: string;
  picture: string;
  position: string;
  startDate: string;
}

export interface Colleagues {
  direct: UserProfile[] | null;
  functional: UserProfile[] | null;
}

export interface PersonalInfoMainInfo extends MainUserInfo {
  status: string;
}

export interface PersonalInfo extends User, PersonalInfoMainInfo, Managers {
  position: string;
  hobby: string;
  startDate: string;
  picture: string;
  phone: string;
  myGoals: string;
  vacationAvailablePaid: number;
  updated: string;
  balance: number;
  is2faEnabled: boolean;
  colleagues: Colleagues;
  teamUsers: Colleagues;
  function: string;
  age: string;
  workFormat: string;
  gender: string;
}

export interface SigninWithCodeData {
  token: string;
  is2faEnabled: boolean;
}

export interface Enable2FAData {
  qrCode: string;
  manualSetupUri: string;
  recoveryCodes: string[];
}

export enum UserRoles {
  User = 'user',
  Admin = 'admin',
}

export type UserStatusType = 'active' | 'probation' | 'offboarding';

export interface UserAdmin extends User {
  email: string;
  status: UserStatusType;
  balance: number;
  functionalBalance: number | null;
  cabinetId?: number;
  isAdmin?: boolean;
}

export interface WardUserAdmin extends UserAdmin {
  team: string;
  position: string;
  picture: string;
  stagesProgress: Nullable<UserStagesProgress>;
  questionnaireStatus: Nullable<UserQuestionnaireStatus>;
  mentorshipId: number;
  coordinationId: number;
  coordinator: UserProfile;
  mentor: UserProfile;
  assignedTemplateName?: string;
}

export interface UserInfoMainInfo extends MainUserInfo {
  status: UserStatusType;
}

export interface UserGroups {
  groups: UserGroup[];
}

export interface AdditionalInfo {
  hobby: string;
  phone: string;
  startDate: string;
}

export interface UserInfoAdmin extends User, AdditionalInfo, UserInfoMainInfo, Managers, UserGroups,
  UserSlackId {
  position: string;
  balance: number;
  picture: string;
  achievements: Achievement[];
}

export interface AccessData {
  refresh: string;
  access: string;
  permissions: string[];
}

export type DevicePlatform = 'desktop' | 'mobile' | 'tablet';

export interface DeviceCredential extends Entity {
  name: string;
  signCount: number;
  createdAt: string;
  platform: DevicePlatform;
}

export interface NotifyOnBday {
  notifyOnBday: boolean;
}

export interface UserSlackId {
  slackId: string;
}

export interface UserVacationInfo {
  vacation: {
    details: string; // TODO: Can be html-like string
    expiredAt: string;
  } | null;
}

export interface UserOrgTree extends Partial<UserSlackId>, UserProfile {
  locationName: string;
  segment: string;
  directManagerId: number | null;
  functionalManagerId: number | null;
  isRoot?: boolean;
  isActive: boolean;
}

export interface BirthdayUser extends UserProfile, NotifyOnBday {
}

export type PersonalInfoAdditionalInfo = Pick<PersonalInfo, 'phone' | 'startDate' | 'hobby'>;
export type PersonalInfoRest = Omit<
  PersonalInfo, keyof PersonalInfoMainInfo | keyof PersonalInfoAdditionalInfo | keyof Managers
>;

export type GlobalSearchPerson = UserProfile;

export type UserInfoRestAdmin = Omit<
  UserInfoAdmin, keyof AdditionalInfo | keyof UserInfoMainInfo | keyof Managers | keyof UserGroups
>;

export type SubordinatesTypes = 'direct' | 'functional';
export type SubordinatesLevels = 'proximate' | 'total';
export type UserType = 'coordinator' | 'mentor';

export type MyTeamUser = WardUserAdmin;
export type CoordinatesUser = WardUserAdmin;
export type MentoryUser = WardUserAdmin;
