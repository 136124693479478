import { Entity, ImageModel } from '@pinup-teams/common';

export enum WelcomeContentType {
  Library = 'library',
  EducationEvents = 'educationEvents',
  Communities = 'communities',
}

export interface WelcomeContent extends Entity {
  image: ImageModel;
  title: string;
  contentType: WelcomeContentType;
  article: string;
  manualEnUrl: string;
  manualUaUrl: string;
  manualRuUrl: string;
  visible: boolean;
}

export interface UserWelcomeContent extends WelcomeContent {
  needsDisplay: boolean;
}
