import { Entity, ImageModel, Nullable } from '@pinup-teams/common';
import { DictAchievement, User, UserSlackId } from '@pt/models';

export enum CourseStatusType {
  InProgress = 'inProgress',
  Completed = 'completed',
  NotPassed = 'notPassed',
  NotStarted = 'notStarted',
}

export enum CourseRequestStatus {
  InReview = 'inReview',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum CourseType {
  Open = 'open',
  Mandatory = 'mandatory',
  Request = 'request',
}

export interface CourseCategory extends Entity {
  name: string;
}

export interface CourseCategoryEditable extends CourseCategory {
  visible: boolean;
}

export interface BaseCourse extends Entity {
  name: string;
}

export interface Course extends BaseCourse {
  reward: number;
  mainImage: ImageModel;
  courseType: CourseType;
}

export interface CourseAchievement {
  achievement: Nullable<DictAchievement>;
}

export interface GalleryCourse extends Course, CourseAchievement {
  isParticipate: boolean;
  isRequested: boolean;
  isApproved: boolean;
  startDeadline: string | null;
  finishDeadline: string | null;
  status: CourseStatusType;
}

export interface CourseDetails extends GalleryCourse {
  lmsCourseId: number;
  description: string;
  extraImages: ImageModel[];
}

export interface AdminCourse extends Course {
  categories: CourseCategory[];
  visible: boolean;
}

export interface QuizReward {
  lmsQuizId: number;
  reward: number;
}

export interface AdminCourseEditable extends Omit<Course, 'reward'> {
  lmsCourseId: number;
  achievementId: number;
  groupIds: number[];
  categoryIds: number[];
  description: string;
  extraImages: ImageModel[];
  visible: boolean;
  quizzesRewards: QuizReward[];
  sendAssignNotifications: boolean;
  startDeadlineDays: number;
  startDeadlineDaysBeforeNotifications: number;
  finishDeadlineDays: number;
  finishDeadlineDaysBeforeNotifications: number;
  deactivatedUsers: User[];
}

export interface MyCourse extends Course, CourseAchievement {
  totalModules: number;
  totalModulesCompleted: number;
  progress: number;
  lmsCourseId: number;
  status: CourseStatusType;
  finishDeadline: string | null;
  isTrainee: boolean;
}

export interface CourseRequest extends Entity {
  createdAt: string;
  status: CourseRequestStatus;
  studentData: User & UserSlackId;
  courseData: BaseCourse;
  moderatorData: Nullable<User>;
  requestComment: Nullable<string>;
  moderatorComment: Nullable<string>;
}
