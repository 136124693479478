import { Entity } from '@pinup-teams/common';

export interface UserFinancialRequest {
  requestId: number;
  requestType: string;
  requestIdHash: string;
  date: Date;
  status: FinancialRequestStatus;
}

export interface AdminFinancialRequest extends Entity {
  createdAt: Date;
  location: string;
  status: FinancialRequestStatus;
  category?: string;
  user: {
    id: number;
    fullName: string;
  };
}

export enum FinancialRequestStatus {
  New = 'new',
  NewEdited = 'newEdited',
  InProgress = 'inProgress',
  InProgressEdited = 'inProgressEdited',
  Rejected = 'rejected',
  Completed = 'completed',
  Canceled = 'canceled',
}

export interface FinancialRequestDetailsHistory {
  status: FinancialRequestStatus;
  createdAt: Date;
}

export interface FinancialRequestDetailsEncryptedComments {
  requestIdHash?: string;
  data: {
    date?: Date;
    message: string;
    fromUser: string;
    status: FinancialRequestStatus;
  };
}

export interface FinancialRequestDetails extends UserFinancialRequest {
  history: FinancialRequestDetailsHistory[];
  comments?: FinancialRequestDetailsEncryptedComments[];
}
