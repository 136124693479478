import { Entity, ImageModel, FileModel, DictionaryItem } from '@pinup-teams/common';

import { Reaction } from '.';

export enum ArticleType {
  News = 'news',
  Faq = 'faq',
  Blog = 'blog',
}

export enum ImageViewModeType {
  Flip = 'flip',
  Gallery = 'gallery',
}

export type RubricTag = DictionaryItem;

export interface News extends Entity {
  title: string;
}

export interface NewsShop extends News {
  tag: RubricTag;
  previewImage: ImageModel;
  image: ImageModel;
  imageViewMode?: ImageViewModeType;
  articleImages: ImageModel[];
  previewText: string;
  fileButtons: ArticleFileButton[];
  article: string;
  publishedAt: string;
  read: boolean;
  saved: boolean;
  articleType: ArticleType;
  mainImage?: ImageModel; // FE field only
  articleImageUrls?: string[]; // FE field only
}

export interface ArticleFileButton {
  buttonName: string;
  file: FileModel;
  isFlipped: boolean;
}

export interface Article extends NewsShop {
  articleType: ArticleType;
  videoUrl?: string;
  pdf?: FileModel;
  linkName?: string;
  linkUrl?: string;
  reactions: Reaction[];
  commentsCount: number;
}

export interface NewsAdmin extends News {
  tag: RubricTag;
  publishedAt: string;
  visible: boolean;
}

export interface NewsForm extends News {
  articleType: ArticleType;
  tagId: number;
  previewImage: ImageModel;
  image: ImageModel;
  previewText: string;
  article: string;
  groupIds: number[];
  slackChannelIds: number[];
  visible: boolean;
  videoUrl?: string;
  pdf?: FileModel;
  fileButtons?: ArticleFileButton[];
  articleImages?: ImageModel[];
  imageViewMode?: ImageViewModeType;
  linkName?: string;
  linkUrl?: string;
}

export interface GlobalSearchArticle extends News {
  tag: RubricTag;
  imageUrl: string;
  publishedAt: string;
}
