import { Entity, ImageModel, FileModel } from '@pinup-teams/common';

import { ArticleType, Author, Reaction, User } from '.';

export enum BlogStatus {
  InReview = 'inReview',
  Approved = 'approved',
}

export interface BlogTitle extends Entity {
  title: string;
}

export interface BlogBase extends BlogTitle {
  previewImage: ImageModel;
  image: ImageModel;
  previewText: string;
  article: string;
  articleType: ArticleType;
}

export interface Blog extends BlogBase {
  publishedAt: string;
  commentsCount: number;
}

export interface BlogForm extends BlogBase {
  authorId: number;
}

export interface BlogArticle extends Blog {
  videoUrl?: string;
  pdf?: FileModel;
  linkName?: string;
  linkUrl?: string;
  reactions: Reaction[];
  author: Author;
}

export interface BlogAdmin extends BlogTitle {
  createdAt: string;
  visible: boolean;
  author: User;
}

export interface BlogAdminForm extends BlogBase {
  groupIds: number[];
  slackChannelIds: number[];
  visible: boolean;
  videoUrl?: string;
  pdf?: FileModel;
  linkName?: string;
  linkUrl?: string;
  author: User;
  status: BlogStatus;
}
