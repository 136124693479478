export enum CommunityRoles {
  Speaker = 'speaker',
  Moderator = 'moderator',
  Expert = 'expert',
  Listener = 'listener',
}

export enum CommunityRequestStatus {
  InReview = 'inReview',
  Approved = 'approved',
  Rejected = 'rejected',
}
