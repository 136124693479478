import { Entity, Nullable } from '@pinup-teams/common';

export enum EventsViewType {
  Calendar = 'calendar',
  Cards = 'cards',
}

export enum EducationEventType {
  Workshop = 'workshop',
  SharingSession = 'sharingSession',
}

export enum SharingSessionType {
  Open = 'open',
  Private = 'private',
  Request = 'requested',
}

export enum SessionAppType {
  GoogleMeet = 'googleMeet',
  Zoom = 'zoom',
}

export enum EducationEventStatus {
  InReview = 'inReview',
  Approved = 'approved',
  Rejected = 'rejected',
  Passed = 'passed',
  Canceled = 'canceled',
}

export enum EducationRequestStatus {
  InReview = 'inReview',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum TimezoneTypes {
  Kiev = 'Europe/Kiev',
  Warsaw = 'Europe/Warsaw',
  Almaty = 'Asia/Almaty',
  Budapest = 'Europe/Budapest',
  Lima = 'America/Lima',
  Nicosia = 'Asia/Nicosia',
}

export enum EducationEventAuthorCommentType {
  ContentReview = 'contentReview',
  SpeakerPreparation = 'speakerPreparation',
  LearningSupport = 'learningSupport',
  GroupAssignment = 'groupAssignment',
}

export type EventDurationOptions = 30 | 60 | 90 | 120 | 180;

export enum EventLanguageOption {
  English = 'EN',
  Russian = 'RU',
  Ukrainian = 'UA',
  Spanish = 'ES',
  Kazakh = 'KZ',
  Polish = 'PL',
  Portuguese = 'PT',
  Armenian = 'AM',
}

export interface EventLanguage extends Entity {
  name: EventLanguageOption;
}

export interface EventTargetAudience extends Entity {
  name: string;
}

export interface AdminEventTargetAudience extends EventTargetAudience {
  visible: boolean;
}

export interface EducationEvent extends Entity {
  title: string;
  startDateTime: string;
  timeZone: TimezoneTypes;
  sessionUrl: string;
  duration: EventDurationOptions;
  imageUrl: string;
  imageName: string;
  eventType: EducationEventType;
  languages: EventLanguage[];
  targetAudiences: EventTargetAudience[];
  authorId: number;
  authorFullName: string;
  participantsCount: number;
}

export interface EducationEventWorkshop extends EducationEvent {
  courseName: string;
}

export interface EducationEventSharingSession extends EducationEvent {
  subtitle: string;
  sessionType: SharingSessionType;
}

export interface EducationEventAdmin extends EducationEvent {
  authorId: number;
  authorFullName: string;
  authorSlackId: string;
  statusesHistory: EducationEventStatusHistoryAdmin<EducationEventStatus>[];
  authorComment: string;
  authorCommentType: Nullable<EducationEventAuthorCommentType>;
}

export interface EducationEventUser extends EducationEvent {
  isParticipating: boolean;
  latestUserStatus: EducationRequestStatus;
  application: SessionAppType;
  isPassed: boolean;
  isRated: boolean;
}

export interface WorkshopEvent extends EducationEventUser {
  courseName: string;
}

export interface SharingSessionEvent extends EducationEventUser {
  subtitle: string;
  sessionType: SharingSessionType;
  registrationDeadline: string;
}

export interface EducationEventStatusHistoryAdmin<StatusType> extends Entity {
  status: StatusType;
  createdAt: string;
  comment: string;
  moderatorName: string;
}

export interface SharingSessionParticipant extends Entity {
  fullName: string;
}

export interface WorkshopEventAdmin extends EducationEventAdmin {
  courseName: string;
}

export interface EventGroup {
  groupId: number;
  groupName: string;
}

export interface SharingSessionEventAdmin extends EducationEventAdmin {
  subtitle: string;
  sessionType: SharingSessionType;
  registrationDeadline: string;
  participantsLimit: number;
  additionalInformation: string;
  participants: SharingSessionParticipant[];
  groups: EventGroup[];
}

export interface EducationEventRequestAdmin extends Entity {
  userId: number;
  userFullName: string;
  educationEventId: number;
  educationEventTitle: string;
  educationEventType: EducationEventType;
  participantsLimit: number;
  approvedParticipantsCount: number;
  statusHistory: EducationEventStatusHistoryAdmin<EducationRequestStatus>[];
}

export interface SpeakerRating extends Entity {
  authorFullName: string;
  sessionsCount: number;
  averageRating: number;
}

export interface SpeakerRate extends Entity {
  fromUserFullName: string;
  sessionName: string;
  rate: number;
  comment: string;
}

export interface CreateEvent {
  title: string;
  languages: EventLanguageOption[];
  sessionUrl: string;
  imageUrl: string;
  imageName: string;
  duration: EventDurationOptions;
  application: SessionAppType;
  startDateTime: string;
  timeZone: TimezoneTypes;
  authorComment: string;
  authorCommentType: EducationEventAuthorCommentType;
  targetAudienceIds: number[];
}

export interface CreateWorkshopEvent extends CreateEvent {
  courseId: number;
}

export interface CreateSharingSessionEvent extends CreateEvent {
  subtitle: string;
  sessionType: SharingSessionType;
  registrationDeadline: string;
  additionalInformation: string;
  selectedUsersIds: number[];
  participantsLimit: number;
}
