import { FileModel } from '@pinup-teams/common/lib/models/file.model';

export enum LibrariesType {
  Request = 'Request',
  Categories = 'Categories',
  Materials = 'Materials',
}

export enum LibraryMaterialTypes {
  ARTICLE = 'article',
  VIDEO = 'video',
  E_BOOK = 'e_book',
  PRESENTATION = 'presentation',
}

export interface LibraryCategory {
  id: number;
  name: string;
  visible?: boolean;
}

export interface LibraryMaterialFlippingBook {
  file: FileModel;
  buttonText: string;
  formatType: FlippingTypes;
}

export enum FlippingTypes {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface LibraryMaterialItem extends LibraryMaterialTableItem {
  coverImage: FileModel;
  categoriesIds: number[];
  libraryTypes: string[];
  language: string;
  description: string;
  videoUrl: string;
  files: FileModel[];
  flippingBook: LibraryMaterialFlippingBook;
}

export interface LibraryMaterialsTableData {
  itemsCount: number;
  materials: LibraryMaterialTableItem[];
}

export interface LibraryMaterialAuthor {
  id: string;
  fullName: string;
}

export interface LibraryMaterialTableItem {
  id: number;
  title: string;
  visible: boolean;
  createdAt: Date;
  categories: string[];
  author: LibraryMaterialAuthor;
}
