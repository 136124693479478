import { FormArray, FormGroup } from '@angular/forms';

import { FormControl } from '@ngneat/reactive-forms';
import { Entity, ImageModel } from '@pinup-teams/common';

import { Achievement } from './achievement.model';
import { UserGroup, UserProfile } from './user.model';

export enum SurveyType {
  TEMPLATE = 'template',
  MANUAL = 'manual',
}

export enum SurveyStatus {
  PLANNED = 'planned',
  FINISHED = 'finished',
  IN_PROGRESS = 'inProgress',
}

export enum QuestionType {
  SHORT = 'short',
  LONG = 'paragraph',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  RATING = 'rating',
  LINEAR_SCALE = 'scale',
  CHOICE_GRID = 'gridRadio',
  CHECKBOX_GRID = 'gridCheckbox',
}

export enum SurveyQuestionIcon {
  LIKE = 'survey-like',
  STAR = 'survey-star',
  HEART = 'survey-heart',
}

export enum Operations {
  GoToSection = 'goToSection',
  NextSection = 'nextSection',
  Submit = 'submitForm',
}

export interface SurveyCharacter {
  id: number;
  name: string;
  image: ImageModel;
}

export interface SurveyPulseData {
  id: number;
  name: string;
  question: string;
  character: SurveyCharacter;
  startDate: string;
  endDate: string;
  groups: UserGroup[];
  users: UserProfile[];
  isAnonymous: boolean;
}

export interface SurveyData extends Entity<number> {
  name: string;
  description: string;
  coverImage: ImageModel;
  startDate: string;
  endDate: string;
  estimatedTime: number;
  reward: number;
  achievement: Achievement;
  isActive: boolean;
  surveyType: SurveyType;
  groups: UserGroup[];
  users: UserProfile[];
  isRecommended: boolean;
  recommendedFor: UserProfile[];
  recommendedEditingFor: UserProfile[];
  isUpdated?: boolean;
  isResponded?: boolean;
  isAnonymous?: boolean;
  sections: SurveySectionData[];
}

export interface SurveySectionData extends Entity<number> {
  title: string;
  description: string;
  actionType: Operations;
  goToSectionOrder: number;
  isUpdated?: boolean;
  questions: SurveyQuestionData[];
}

export interface SurveyQuestionData extends Entity<number> {
  questionText: string;
  questionType: QuestionType;
  lowerLabel: string;
  upperLabel: string;
  iconName: SurveyQuestionIcon;
  isRequired: boolean;
  isUpdated?: boolean;
  options: SurveyOptionData[];
  rows: SurveySubquestionRowData[];
  columns: SurveySubquestionColumnData[];
}

export interface SurveySubquestionRowData extends Entity<number> {
  title: string;
}

export interface SurveySubquestionColumnData extends Entity<number> {
  title: string;
}

export interface SurveyOptionData extends Entity<number> {
  choiceText: string;
  actionType: Operations;
  isUpdated?: boolean;
  goToSectionOrder: number;
}

export type SurveyShortData = Omit<SurveyData, 'sections'>;

export interface SurveyQuestionRow {
  id: FormControl<number>;
  title: FormControl<string>;
  isUpdated: FormControl<boolean>;
}

export interface SurveyQuestionColumn {
  id: FormControl<number>;
  title: FormControl<string>;
  isUpdated: FormControl<boolean>;
}

export interface SurveyQuestionOption {
  id: FormControl<number>;
  choiceText: FormControl<string>;
  actionType: FormControl<Operations>;
  goToSectionOrder: FormControl<number>;
  isUpdated: FormControl<boolean>;
}

export interface SurveyQuestion {
  id: FormControl<number>;
  questionText: FormControl<string>;
  questionType: FormControl<QuestionType>;
  lowerLabel: FormControl<string>;
  upperLabel: FormControl<string>;
  iconName: FormControl<SurveyQuestionIcon>;
  isRequired: FormControl<boolean>;
  options: FormArray<FormGroup<SurveyQuestionOption>>;
  rows: FormArray<FormGroup<SurveyQuestionRow>>;
  columns: FormArray<FormGroup<SurveyQuestionColumn>>;
  isUpdated: FormControl<boolean>;
}

export interface SurveySection {
  id: FormControl<number>;
  title: FormControl<string>;
  description: FormControl<string>;
  actionType: FormControl<Operations>;
  goToSectionOrder: FormControl<number>;
  questions: FormArray<FormGroup<SurveyQuestion>>;
  isUpdated: FormControl<boolean>;
}

export interface SurveyForm {
  id: FormControl<number>;
  name: FormControl<string>;
  description: FormControl<string>;
  coverImage: FormControl<ImageModel>;
  startDate: FormControl<string>;
  endDate: FormControl<string>;
  estimatedTime: FormControl<number>;
  reward: FormControl<number>;
  achievementId: FormControl<number>;
  isActive: FormControl<boolean>;
  surveyType: FormControl<SurveyType>;
  groups: FormControl<number[]>;
  users: FormControl<UserProfile[]>;
  isRecommended: FormControl<boolean>;
  recommendedFor: FormControl<UserProfile[]>;
  recommendedEditingFor: FormControl<UserProfile[]>;
  isUpdated: FormControl<boolean>;
  isAnonymous: FormControl<boolean>;
  sections: FormArray<FormGroup<SurveySection>>;
}
