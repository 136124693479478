import { Entity } from '@pinup-teams/common';

import { OnboardingPreviewTask, UserOnboardingTask } from './onboarding-task.model';
import { DictAchievement } from './achievement.model';
import { UserProfile } from './user.model';

export type OnboardingStageStatus = 'completed' | 'inProgress' | null;

export enum OnboardingTemplateOwnershipType {
  Recommended = 'recommended',
  Own = 'own',
}

export type OnboardingType = 'corporate' | 'professional' | 'mentoring';

export interface OnboardingTemplate extends Entity {
  name: string;
}

export interface OnboardingStage extends Entity {
  name: string;
  subtitle: string;
}

export interface UserOnboardingStage extends OnboardingStage {
  reward: number; // either reward or expiredReward, depending on the expiration status
  achievement: DictAchievement;
  optionalTasks: UserOnboardingTask[];
  requiredTasks: UserOnboardingTask[];
  totalOptionalCompleted: number;
  totalRequiredCompleted: number;
  startedAt: string | null;
  expiredAt: string | null;
  status: OnboardingStageStatus;
  userOnboardingId: number;
  onboardingType: OnboardingType;
  stageNumber: number;
}

export interface OnboardingPreviewAchievement extends DictAchievement {
  description: string;
}

export interface OnboardingPreviewStage extends OnboardingStage {
  status: string;
  reward: number;
  onboardingType: OnboardingType;
  achievement: OnboardingPreviewAchievement;
  requiredTasks: OnboardingPreviewTask[];
  optionalTasks: OnboardingPreviewTask[];
}

export interface OnboardingPreview {
  stages: OnboardingPreviewStage[];
}

export interface AdminOnboardingTemplate extends OnboardingTemplate {
  amountOfStages: number;
  amountOfTasks: number;
  visible: boolean;
  canEdit?: boolean;
  isDeletable: boolean;
  ownershipType: OnboardingTemplateOwnershipType;
}

export interface AdminOnboardingStage extends OnboardingStage {
  reward?: number;
  achievementId: number;
  daysToComplete: number;
  expiredReward?: number;
  optionalTaskIds: number[];
  requiredTaskIds: number[];
}

export interface AdminOnboardingTemplateDetails extends OnboardingTemplate {
  visible: boolean;
  stages: AdminOnboardingStage[];
  recommendationType: RecommendationType;
  usersCanEdit?: UserProfile[];
  allUsersCanEdit?: boolean;
  usersCanView?: UserProfile[];
  allUsersCanView?: boolean;
  isAssigned: boolean;
  canEdit: boolean;
}

export interface UserStagesProgress {
  totalStages: number;
  totalStagesCompleted: number;
}

export interface AdminUserOnboardingStage {
  id: number;
  name: string;
  status: OnboardingStageStatus;
  requiredTasks: UserOnboardingTask[];
  optionalTasks: UserOnboardingTask[];
  totalRequiredCompleted: number;
  totalOptionalCompleted: number;
}

export interface AdminUserOnboardingDetails extends UserStagesProgress {
  id: number;
  progress: number;
  stages: AdminUserOnboardingStage[];
  canCancel: boolean;
  onboardingType: OnboardingType;
}

export enum RecommendationType {
  NotRecommended = 'notRecommended',
  RecommendedSpecifically = 'recommendedSpecifically',
}

export interface Onboarding {
  totalStages: number;
  totalStagesCompleted: number;
  stages: UserOnboardingStage[];
  progress: number;
  onboardingType: OnboardingType;
  userOnboardingId: number;
}
